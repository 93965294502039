import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge, Button, Card, Spin, Tabs, Tooltip } from "antd";
import { CopyOutlined, DeleteOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";

import Constants, { TYPE_TAG } from "../../utils/constants";
import { getNomLocataire } from "../../utils/tools";

import {
  processWord,
  convertToPdf,
  deleteDocument,
  saveDocument,
  updateDocument,
} from "../../store/actions/documentAction";
import {
  deleteLocation,
  loadLocations,
  saveLocation,
  updateLocation,
} from "../../store/actions/locationAction";
import {
  deleteProvenance,
  saveProvenance,
  updateProvenance,
} from "../../store/actions/provenanceAction";
import {
  deleteQuittance,
  loadQuittances,
  saveQuittance,
  updateQuittance,
} from "../../store/actions/quittanceAction";
import {
  deleteQuittanceLigne,
  saveQuittanceLigne,
  updateQuittanceLigne,
} from "../../store/actions/quittanceLigneAction";
import { saveOccupants, updateOccupants } from "../../store/actions/locationAction";
import {
  deleteLocationLigne,
  saveLocationLigne,
  updateLocationLigne,
} from "../../store/actions/locationLigneAction";
import { deleteRecherche, saveRecherche } from "../../store/actions/rechercheAction";
import {
  deleteRenduA,
  loadRenduAs,
  saveRenduA,
  updateRenduA,
} from "../../store/actions/renduAction";
import {
  deleteRequete,
  loadRequetes,
  saveRequete,
  updateRequete,
} from "../../store/actions/requeteAction";
import {
  deleteRequeteLot,
  loadRequeteLots,
  updateAllRequetesLots,
  updateRequeteLot,
} from "../../store/actions/requeteLotAction";
import { deleteTag, saveTag, updateTag } from "../../store/actions/tagAction";
import { saveDossier, updateDossier } from "../../store/actions/dossierAction";
import {
  loadLocataires,
  saveLocataire,
  updateLocataire,
} from "../../store/actions/locataireAction";
import { loadEcritures, saveEcriture } from "../../store/actions/ecritureAction";
import { saveRequeteLot } from "../../store/actions/requeteLotAction";
import { saveLocataireAffectation } from "../../store/actions/locataireAffectationAction";
import { saveProprietaireAffectation } from "../../store/actions/proprietaireAffectationAction";
import { initTree } from "../../store/actions/treeAction";
import { deleteEmail, saveEmail } from "../../store/actions/emailAction";
import { deleteAllRequeteLot } from "../../store/actions/requeteLotAction";
import { loadLots } from "../../store/actions/lotAction";
import { loadComptes } from "../../store/actions/compteAction";
import { deleteReglement, loadReglements } from "../../store/actions/reglementAction";
import { saveMenage } from "../../store/actions/menageAction";
import { deleteNote, saveNote, updateNote } from "../../store/actions/noteAction";
import { deleteFeedback, saveFeedback, updateFeedback } from "../../store/actions/feedbackAction";

import SelectRequete from "../../components/SelectRequete";
import FormMail from "../../components/FormMail/index";
import FormContrat from "../../components/FormContrat";
import FormDocument from "../../components/FormDocument/index";
import TableNote from "../../components/TableNote";

import { toast } from "react-toastify";
import TableFeedback from "../../components/TableFeedback";
import {
  deleteLigneMatrice,
  saveLigneMatrice,
  updateLigneMatrice,
} from "../../store/actions/matriceLigneAction";
import { deletePrixBase, savePrixBase, updatePrixBase } from "../../store/actions/prixBaseAction";

class PanelHautLarge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requete: null,
      location: null,
      locationQuittances: null,
      locationReglements: null,
      document: null,
      label: "",
      activeKey: "1",
      isArchive: false,
    };
  }

  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    Promise.all([
      !this.props.requetes && this.props.loadRequetes(groupement_id),
      !this.props.requetesLots && this.props.loadRequeteLots(groupement_id),
      !this.props.lots && this.props.loadLots(societe_id),
      !this.props.locataires && this.props.loadLocataires(groupement_id),
      !this.props.locations && this.props.loadLocations(groupement_id),
      !this.props.comptes && this.props.loadComptes(societe_id),
      !this.props.quittances && this.props.loadQuittances(groupement_id),
      !this.props.ecritures && this.props.loadEcritures(societe_id),
      !this.props.reglements && this.props.loadReglements(societe_id),
    ]).then((res) => {
      this.init(this.props);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.requete !== this.props.requete ||
      nextProps.locations !== this.props.locations ||
      nextProps.quittances !== this.props.quittances
    ) {
      if (nextProps.requetes && nextProps.requetesLots) {
        this.init(nextProps);
      }
    } else if (nextProps.requete === null) this.reset();
  }

  init = (props) => {
    if (props.requete !== null && props.requete !== undefined) {
      this.getLocation(
        props.requete.requete_id,
        props.locations,
        props.quittances,
        props.reglements
      );
      this.checkDossier(props.requete);
      this.setState({
        activeKey:
          this.state.activeKey === "1" && this.props.tabContrat ? "3" : this.state.activeKey,
        requete: props.requete,
      });
    }
  };

  reset = () => {
    this.setState({ location: null });
  };

  resync = (activeKey) => {
    this.init(this.props);
    activeKey && this.setState({ activeKey });
  };

  syncRequete = (requete) => {
    this.props.syncRequete(requete);
  };

  handleCreateRequete = (locataire) => {
    this.props.saveDossier(this.getJsonDossier()).then((dossier) => {
      this.props
        .saveRequete({
          requete_locataire_id: locataire.locataire_id,
          requete_provenance_id: null,
          requete_dossier_id: dossier ? dossier.dossier_id : null,
        })
        .then((req) => {
          this.props
            .updateDossier(this.getJsonDossier(req), dossier.dossier_id)
            .then((dos) => (req.requete_dossier = dos));

          this.props.syncRequete(req);

          toast.success(`Requête créée pour ${getNomLocataire(locataire)}`, {
            containerId: "A",
          });
        });
    });
  };

  getJsonDossier = (requete) => {
    return {
      dossier_url: requete ? `Requetes/${requete.requete_id}/` : Constants.directory,
    };
  };

  getLocation(requete_id, locations, quittances, reglements) {
    if (locations !== null && locations !== undefined) {
      const location = locations.find((item) => {
        return (
          item.location_requete &&
          item.location_requete.requete_id === requete_id &&
          (item.location_etat === "r" || item.location_etat === "o" || item.location_etat === "a")
        );
      });
      if (location !== undefined) {
        this.getQuittances(location.location_id, quittances);
        this.getReglements(location.location_id, reglements);
      }
      this.setState({ location });
    }
  }

  checkDossier = (requete) => {
    if (
      (requete.requete_dossier === null || requete.requete_dossier === undefined) &&
      !requete.requete_dossier_id
    ) {
      this.props.saveDossier(this.getJsonDossier(requete)).then((dossier) => {
        requete.requete_dossier_id = dossier ? dossier.dossier_id : null;
        requete.requete_locataire_id = requete.requete_locataire.locataire_id;
        requete.requete_provenance_id = requete.requete_provenance?.provenance_id;
        requete.requete_groupe_id = requete.requete_groupe?.groupe_id;
        requete.requete_fin = false;
        this.props
          .updateRequete(requete, requete.requete_id)
          .then((req) => this.props.syncRequete(req));
      });
    }
  };

  getQuittances(location_id, quittances) {
    if (quittances !== null && quittances !== undefined) {
      const locationQuittances = quittances.filter((item) => {
        return item.quittance_location_id === location_id;
      });
      this.setState({ locationQuittances });
    }
  }

  getReglements(location_id, reglements) {
    if (reglements !== null && reglements !== undefined) {
      const locationReglements = reglements.filter((item) => {
        return item.reglement_quittance.quittance_location_id === location_id;
      });
      this.setState({ locationReglements });
    }
  }

  onChangeTab = (activeKey, document, label) => {
    this.setState({ activeKey, document, label });
  };

  onClickArchiverRequete = () => {
    this.state.requete &&
      this.props
        .updateRequete(
          {
            requete_fin: true,
            requete_groupe_id: this.state.requete.requete_groupe?.groupe_id,
            requete_provenance_id: this.state.requete.requete_provenance?.provenance_id,
            requete_locataire_id: this.state.requete.requete_locataire?.locataire_id,
            requete_dossier_id: this.state.requete.requete_dossier?.dossier_id,
            requete_commentaire: this.state.requete.requete_commentaire,
          },
          this.state.requete.requete_id
        )
        .then((req) => {
          this.props.syncRequete(req);
          this.props.initTree(this.props.utilisateur.utilisateur_id);
          toast.success(`Requête archivée`, {
            containerId: "A",
          });
        });
  };

  onClickDesarchiverRequete = () => {
    this.state.requete &&
      this.props
        .updateRequete(
          {
            requete_fin: false,
            requete_groupe_id: this.state.requete.requete_groupe?.groupe_id,
            requete_provenance_id: this.state.requete.requete_provenance?.provenance_id,
            requete_locataire_id: this.state.requete.requete_locataire?.locataire_id,
            requete_dossier_id: this.state.requete.requete_dossier?.dossier_id,
            requete_commentaire: this.state.requete.requete_commentaire,
          },
          this.state.requete.requete_id
        )
        .then((req) => {
          this.props.syncRequete(req);
          this.props.initTree(this.props.utilisateur.utilisateur_id);
          toast.success(`Requête désarchivée`, {
            containerId: "A",
          });
        });
  };

  onClickDupliquer = () => {
    const { requete } = this.state;

    this.props.saveDossier(this.getJsonDossier()).then((dossier) => {
      this.props
        .saveRequete({
          requete_locataire_id: requete.requete_locataire.locataire_id,
          requete_groupe_id: null,
          requete_provenance_id: requete.requete_provenance?.provenance_id,
          requete_dossier_id: dossier ? dossier.dossier_id : null,
          requete_commentaire: requete.requete_commentaire,
        })
        .then((req) => {
          const requetesLots = this.props.requetesLots.filter(
            (item) => requete.requete_id === item.requete_lot_requete.requete_id
          );

          requetesLots.forEach((requeteLot) => {
            this.props.saveRequeteLot({
              requete_lot_requete_id: req.requete_id,
              requete_lot_lot_id: requeteLot.requete_lot_lot.lot_id,
              requete_lot_date_debut: requeteLot.requete_lot_date_debut,
              requete_lot_date_fin: requeteLot.requete_lot_date_fin,
              requete_lot_type: 1,
            });
          });

          this.props
            .updateDossier(this.getJsonDossier(req), dossier.dossier_id)
            .then((dos) => (req.requete_dossier = dos));

          this.props.syncRequete(req);

          toast.success(`Requête dupliquée !}`, {
            containerId: "A",
          });
        });
    });
  };

  render() {
    const {
      requete,
      location,
      locationQuittances,
      locationReglements,
      document,
      label,
      activeKey,
      isArchive,
    } = this.state;

    const {
      utilisateur,
      journals,
      comptes,
      locataires,
      requetes,
      requetesLots,
      lots,
      typesLots,
      equipements,
      champsPersos,
      champsPersosGroupes,
      locations,
      quittances,
      ecritures,
      reglements,
      provenances,
      groupes,
      renduAs,
      parcoursClients,
      parametre,
      tabKey,
    } = this.props;

    const dossier = requete ? requete.requete_dossier : null;

    const action = (
      <>
        <Tooltip title="Sauvegarder">
          <Button
            form={`form-requete-${requete ? requete.requete_id : "null"}`}
            key="submit"
            type="primary"
            htmlType="submit"
            style={{ marginRight: 5 }}
            disabled={requete === null || isArchive}
          >
            <SaveOutlined style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
        <Tooltip title="Dupliquer">
          <Button
            key={"2"}
            type="primary"
            style={{ marginRight: 20 }}
            onClick={this.onClickDupliquer}
            disabled={requete === null || isArchive}
          >
            <CopyOutlined style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
        {requete?.requete_fin ? (
          <Tooltip title="Désarchiver">
            <Button
              key={"1"}
              type="primary"
              danger
              style={{ marginRight: 20 }}
              onClick={this.onClickDesarchiverRequete}
              disabled={location && location.location_etat !== "a"}
            >
              <UndoOutlined style={{ fontSize: 20 }} />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Archiver">
            <Button
              key={"1"}
              type="primary"
              danger
              style={{ marginRight: 20 }}
              onClick={this.onClickArchiverRequete}
              disabled={(location && location.location_etat !== "a") || requete === null}
            >
              <DeleteOutlined style={{ fontSize: 20 }} />
            </Button>
          </Tooltip>
        )}
      </>
    );

    const backgroundColor = requete?.requete_fin ? "#ddd" : "#fff";

    return (
      <Spin
        spinning={
          !lots ||
          !locataires ||
          !locations ||
          !comptes ||
          !requetes ||
          !requetesLots ||
          !quittances ||
          !ecritures
        }
        size="large"
        tip="Chargement en cours..."
      >
        <Card
          title=""
          style={{ backgroundColor: backgroundColor }}
          bordered={false}
          className="card-panel"
        >
          <div style={{ minHeight: 550, display: "grid" }}>
            <Tabs
              animated={false}
              defaultActiveKey="1"
              activeKey={activeKey}
              tabBarExtraContent={parametre.parametre_parcours_client ? action : null}
              size="large"
              onChange={this.onChangeTab}
              items={[
                {
                  label: "Requête",
                  key: "1",
                  children: (
                    <SelectRequete
                      utilisateur={utilisateur}
                      requete={requete}
                      locataires={locataires}
                      requetes={requetes.sort((a, b) => b.requete_id - a.requete_id)}
                      requetesLots={requetesLots}
                      lots={lots}
                      typesLots={typesLots}
                      equipements={equipements}
                      champsPersos={champsPersos}
                      champsPersosGroupes={champsPersosGroupes}
                      location={location}
                      locations={
                        locations &&
                        locations.filter(
                          (item) => item.location_lot.lot_societe_id === utilisateur.societe_id
                        )
                      }
                      quittances={quittances}
                      provenances={provenances}
                      groupes={groupes}
                      renduAs={renduAs}
                      parcoursClients={parcoursClients}
                      parametre={parametre}
                      tabKey={tabKey}
                      onChangeTab={this.onChangeTab}
                      handleCreateRequete={this.handleCreateRequete}
                      syncRequete={this.syncRequete}
                      addPanesPersonne={this.props.addPanesPersonne}
                      addPanesLot={this.props.addPanesLot}
                      saveRecherche={this.props.saveRecherche}
                      deleteRecherche={this.props.deleteRecherche}
                      updateRequeteLot={this.props.updateRequeteLot}
                      updateAllRequetesLots={this.props.updateAllRequetesLots}
                      saveLocation={this.props.saveLocation}
                      updateLocation={this.props.updateLocation}
                      deleteLocation={this.props.deleteLocation}
                      deleteRequeteLot={this.props.deleteRequeteLot}
                      deleteAllRequeteLot={this.props.deleteAllRequeteLot}
                      saveRequeteLot={this.props.saveRequeteLot}
                      saveRenduA={this.props.saveRenduA}
                      updateRenduA={this.props.updateRenduA}
                      initTree={this.props.initTree}
                      updateRequete={this.props.updateRequete}
                      loadRenduAs={this.props.loadRenduAs}
                      saveProvenance={this.props.saveProvenance}
                      updateProvenance={this.props.updateProvenance}
                      deleteProvenance={this.props.deleteProvenance}
                    />
                  ),
                },
                {
                  label: "Courriel",
                  key: "2",
                  disabled: !requete,
                  children: (
                    <FormMail
                      home={false}
                      isArchive={isArchive}
                      utilisateur={utilisateur}
                      requete={requete}
                      location={location}
                      locationQuittances={locationQuittances}
                      locationReglements={locationReglements}
                      document={document}
                      label={label}
                      renduAs={this.props.renduAs}
                      langues={this.props.langues}
                      requetes={requetes}
                      requetesLots={requetesLots}
                      lots={lots}
                      parametre={parametre}
                      templates={this.props.templates}
                      templatesLangues={this.props.templatesLangues}
                      etapesTemplates={this.props.etapesTemplates}
                      fraisAgence={this.props.fraisAgence}
                      caution={this.props.caution}
                      acompte={null}
                      rubriques={this.props.rubriques}
                      saveEmail={this.props.saveEmail}
                      deleteEmail={this.props.deleteEmail}
                    />
                  ),
                },
                {
                  label: location && location.location_etat === "o" ? "Option" : "Contrat",
                  key: "3",
                  disabled: !requete || !location || location.location_etat === "a",
                  children: (
                    <>
                      {location && (
                        <FormContrat
                          resync={this.resync}
                          onChangeTab={this.onChangeTab}
                          utilisateur={this.props.utilisateur}
                          dossier={dossier}
                          tags={this.props.tags
                            .filter((tag) => tag.tag_type === TYPE_TAG[2].value)
                            .sort((a, b) => a.tag_id - b.tag_id)}
                          home={false}
                          isArchive={isArchive}
                          requete={requete}
                          location={location}
                          journals={journals}
                          comptes={comptes}
                          champsPersos={champsPersos}
                          champsPersosGroupes={champsPersosGroupes}
                          typesLots={typesLots}
                          caution={this.props.caution}
                          acompte={null}
                          fraisAgence={this.props.fraisAgence}
                          lots={lots}
                          locations={
                            locations &&
                            locations.filter(
                              (item) => item.location_lot.lot_societe_id === utilisateur.societe_id
                            )
                          }
                          requetesLots={requetesLots}
                          words={this.props.words}
                          rubriques={this.props.rubriques}
                          templatesLangues={this.props.templatesLangues}
                          ecritures={ecritures}
                          reglements={reglements}
                          parametre={parametre}
                          locationQuittances={locationQuittances}
                          saveQuittance={this.props.saveQuittance}
                          updateQuittance={this.props.updateQuittance}
                          deleteQuittance={this.props.deleteQuittance}
                          saveQuittanceLigne={this.props.saveQuittanceLigne}
                          updateQuittanceLigne={this.props.updateQuittanceLigne}
                          deleteQuittanceLigne={this.props.deleteQuittanceLigne}
                          saveOccupants={this.props.saveOccupants}
                          updateOccupants={this.props.updateOccupants}
                          saveLocationLigne={this.props.saveLocationLigne}
                          updateLocationLigne={this.props.updateLocationLigne}
                          deleteLocationLigne={this.props.deleteLocationLigne}
                          updateLocation={this.props.updateLocation}
                          saveEcriture={this.props.saveEcriture}
                          deleteReglement={this.props.deleteReglement}
                          saveLocataireAffectation={this.props.saveLocataireAffectation}
                          saveProprietaireAffectation={this.props.saveProprietaireAffectation}
                          updateRequeteLot={this.props.updateRequeteLot}
                          saveDocument={this.props.saveDocument}
                          processWord={this.props.processWord}
                          convertToPdf={this.props.convertToPdf}
                          saveEmail={this.props.saveEmail}
                          deleteDocument={this.props.deleteDocument}
                          updateDocument={this.props.updateDocument}
                          addPanesReglement={this.props.addPanesReglement}
                          addPanesQuittancement={this.props.addPanesQuittancement}
                          saveMenage={this.props.saveMenage}
                          saveLigneMatrice={this.props.saveLigneMatrice}
                          updateLigneMatrice={this.props.updateLigneMatrice}
                          deleteLigneMatrice={this.props.deleteLigneMatrice}
                          savePrixBase={this.props.savePrixBase}
                          updatePrixBase={this.props.updatePrixBase}
                          deletePrixBase={this.props.deletePrixBase}
                        />
                      )}
                    </>
                  ),
                },
                {
                  label: "Feedback",
                  key: "4",
                  disabled: !location,
                  children: (
                    <TableFeedback
                      location={location}
                      saveFeedback={this.props.saveFeedback}
                      updateFeedback={this.props.updateFeedback}
                      deleteFeedback={this.props.deleteFeedback}
                    />
                  ),
                },
                {
                  label: "Documents",
                  key: "5",
                  disabled: !requete,
                  children: (
                    <FormDocument
                      isArchive={isArchive}
                      utilisateur={this.props.utilisateur}
                      location={location}
                      parametre={parametre}
                      dossier={dossier}
                      documents={this.props.documents}
                      tags={this.props.tags.filter((tag) => tag.tag_type === TYPE_TAG[2].value)}
                      typeTag={3}
                      saveDocument={this.props.saveDocument}
                      updateDocument={this.props.updateDocument}
                      deleteDocument={this.props.deleteDocument}
                      onChangeTab={this.onChangeTab}
                      fromRequete={true}
                      saveTag={this.props.saveTag}
                      updateTag={this.props.updateTag}
                      deleteTag={this.props.deleteTag}
                      saveEmail={this.props.saveEmail}
                    />
                  ),
                },
                {
                  label: (
                    <>
                      {requete?.notes.length > 0 ? <strong>Bloc Note</strong> : "Bloc Note"}{" "}
                      <Badge
                        count={requete ? requete.notes.filter((note) => !note.fin).length : 0}
                      />
                    </>
                  ),
                  key: "6",
                  disabled: !requete,
                  children: (
                    <TableNote
                      utilisateur={this.props.utilisateur}
                      requete={true}
                      data={requete}
                      saveNote={this.props.saveNote}
                      updateNote={this.props.updateNote}
                      deleteNote={this.props.deleteNote}
                    />
                  ),
                },
              ]}
            />
          </div>
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadRequetes: (groupement_id) => dispatch(loadRequetes(groupement_id)),
    loadRequeteLots: (groupement_id) => dispatch(loadRequeteLots(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadRenduAs: (etablissement_id) => dispatch(loadRenduAs(etablissement_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    saveRenduA: (jsonData) => dispatch(saveRenduA(jsonData)),
    updateRenduA: (jsonData, idRequete) => dispatch(updateRenduA(jsonData, idRequete)),
    deleteRenduA: (idRequete, idEtape) => dispatch(deleteRenduA(idRequete, idEtape)),
    saveRequete: (jsonData) => dispatch(saveRequete(jsonData)),
    updateRequete: (jsonData, id) => dispatch(updateRequete(jsonData, id)),
    deleteRequete: (id) => dispatch(deleteRequete(id)),
    saveLocataire: (jsonData) => dispatch(saveLocataire(jsonData)),
    updateLocataire: (jsonData, id) => dispatch(updateLocataire(jsonData, id)),
    saveEmail: (jsonData) => dispatch(saveEmail(jsonData)),
    deleteEmail: (id) => dispatch(deleteEmail(id)),
    saveDocument: (jsonData, idDossier, tag) => dispatch(saveDocument(jsonData, idDossier, tag)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
    updateDocument: (jsonData, id) => dispatch(updateDocument(jsonData, id)),
    processWord: (jsonData, idWord, idDossier) =>
      dispatch(processWord(jsonData, idWord, idDossier)),
    convertToPdf: (jsonData, id) => dispatch(convertToPdf(jsonData, id)),
    saveDossier: (jsonData) => dispatch(saveDossier(jsonData)),
    updateDossier: (jsonData, id) => dispatch(updateDossier(jsonData, id)),
    updateRequeteLot: (jsonData, idRequete, idLot, requeteLotType) =>
      dispatch(updateRequeteLot(jsonData, idRequete, idLot, requeteLotType)),
    updateAllRequetesLots: (requeteLot) => dispatch(updateAllRequetesLots(requeteLot)),
    deleteRequeteLot: (idR, idL, typeRL) => dispatch(deleteRequeteLot(idR, idL, typeRL)),
    saveLocation: (jsonData) => dispatch(saveLocation(jsonData)),
    updateLocation: (jsonData, id) => dispatch(updateLocation(jsonData, id)),
    deleteLocation: (id) => dispatch(deleteLocation(id)),
    saveProvenance: (jsonData) => dispatch(saveProvenance(jsonData)),
    updateProvenance: (jsonData, id) => dispatch(updateProvenance(jsonData, id)),
    deleteProvenance: (id) => dispatch(deleteProvenance(id)),
    saveRecherche: (jsonData) => dispatch(saveRecherche(jsonData)),
    deleteRecherche: (id) => dispatch(deleteRecherche(id)),
    deleteAllRequeteLot: (id) => dispatch(deleteAllRequeteLot(id)),
    saveTag: (jsonData) => dispatch(saveTag(jsonData)),
    updateTag: (jsonData, id) => dispatch(updateTag(jsonData, id)),
    deleteTag: (id) => dispatch(deleteTag(id)),
    saveQuittance: (jsonData) => dispatch(saveQuittance(jsonData)),
    updateQuittance: (jsonData, id) => dispatch(updateQuittance(jsonData, id)),
    deleteQuittance: (id) => dispatch(deleteQuittance(id)),
    saveQuittanceLigne: (jsonData) => dispatch(saveQuittanceLigne(jsonData)),
    updateQuittanceLigne: (jsonData, id) => dispatch(updateQuittanceLigne(jsonData, id)),
    deleteQuittanceLigne: (id) => dispatch(deleteQuittanceLigne(id)),
    saveOccupants: (jsonData, id) => dispatch(saveOccupants(jsonData, id)),
    updateOccupants: (jsonData, id) => dispatch(updateOccupants(jsonData, id)),
    saveLocationLigne: (jsonData) => dispatch(saveLocationLigne(jsonData)),
    updateLocationLigne: (jsonData, id) => dispatch(updateLocationLigne(jsonData, id)),
    deleteLocationLigne: (id) => dispatch(deleteLocationLigne(id)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
    deleteReglement: (id) => dispatch(deleteReglement(id)),
    saveRequeteLot: (jsonData) => dispatch(saveRequeteLot(jsonData)),
    saveLocataireAffectation: (jsonData, id) => dispatch(saveLocataireAffectation(jsonData, id)),
    saveProprietaireAffectation: (jsonData, id) =>
      dispatch(saveProprietaireAffectation(jsonData, id)),
    initTree: (utilisateur_id) => dispatch(initTree(utilisateur_id)),
    saveMenage: (jsonData) => dispatch(saveMenage(jsonData)),
    saveNote: (jsonData) => dispatch(saveNote(jsonData)),
    updateNote: (jsonData, id) => dispatch(updateNote(jsonData, id)),
    deleteNote: (id) => dispatch(deleteNote(id)),
    saveFeedback: (jsonData) => dispatch(saveFeedback(jsonData)),
    updateFeedback: (jsonData, id) => dispatch(updateFeedback(jsonData, id)),
    deleteFeedback: (id) => dispatch(deleteFeedback(id)),
    saveLigneMatrice: (jsonData, lot) => dispatch(saveLigneMatrice(jsonData, lot)),
    updateLigneMatrice: (jsonData, idMatrice, idRubrique, lot) =>
      dispatch(updateLigneMatrice(jsonData, idMatrice, idRubrique, lot)),
    deleteLigneMatrice: (idMatrice, idRubrique, lot) =>
      dispatch(deleteLigneMatrice(idMatrice, idRubrique, lot)),
    savePrixBase: (jsonData, lot) => dispatch(savePrixBase(jsonData, lot)),
    updatePrixBase: (jsonData, idPrixBase, idRubrique, lot) =>
      dispatch(updatePrixBase(jsonData, idPrixBase, idRubrique, lot)),
    deletePrixBase: (idPrixBase, lot) => dispatch(deletePrixBase(idPrixBase, lot)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    journals: state.journals.data,
    comptes: state.comptes.data,
    groupes: state.groupes.data,
    requetes: state.requetes.data,
    renduAs: state.renduAs.data,
    parcoursClients: state.parcoursClients.data,
    locataires: state.locataires.data,
    qualites: state.qualites.data,
    typesPaiements: state.typesPaiements.data,
    langues: state.langues.data,
    documents: state.documents.data,
    lots: state.lots.data,
    typesLots: state.typesLots.data,
    requetesLots: state.requetesLots.data,
    locations: state.locations.data,
    equipements: state.equipements.data,
    templates: state.templates.data,
    provenances: state.provenances.data,
    etapesTemplates: state.etapesTemplates.data,
    templatesLangues: state.templatesLangues.data,
    champsPersosGroupes: state.champsPersosGroupes.data,
    champsPersos: state.champsPersos.data,
    tags: state.tags.data,
    quittances: state.quittances.data,
    ecritures: state.ecritures.data,
    parametre: state.parametre.data,
    caution: state.caution.data,
    fraisAgence: state.fraisAgence.data,
    rubriques: state.rubriques.data,
    words: state.words.data,
    reglements: state.reglements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelHautLarge);
