import React, { Component } from "react";
import { toast } from "react-toastify";
import { DeleteOutlined, EyeOutlined, WarningOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Select,
  Table,
  Button,
  Popconfirm,
  Popover,
  DatePicker,
  Tooltip,
} from "antd";
import { formatDateToBDD, formatDateToAPP } from "../../utils/tools";

import dayjs from "dayjs";

const dateFormat = "DD/MM/YYYY";

const { Option, OptGroup } = Select;
const { Column } = Table;
const { RangePicker } = DatePicker;

class FormApptSelectionnes extends Component {
  state = {
    requete: null,
    lotsAjoutables: [],
    selectedRows: [],
    suppressionOption: false,
    dates: [dayjs(), dayjs()],
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    const lotsAjoutables = props.lots?.filter((lot) => {
      return (
        props.requetesLots
          .filter((req) => req.requete_lot_type === 1)
          .find((req) => req.requete_lot_lot.lot_id === lot.lot_id) === undefined
      );
    });
    this.setState({
      requete: props.requete,
      requetesLots: props.requetesLots.filter((req) => req.requete_lot_type === 1),
      lotsAjoutables,
    });
  };

  onConfirmDelete = (appartement) => {
    if (!this.props.location || appartement.lot_id !== this.props.location.location_lot.lot_id) {
      this.props.deleteRequeteLot(this.state.requete.requete_id, appartement.lot_id, 1).then(() =>
        toast.success("Le lot n'est plus lié à la requête !", {
          containerId: "A",
        })
      );
    } else {
      toast.error("Suppression impossible, une location existe pour ce lot !", {
        containerId: "A",
      });
    }
  };

  onClickAfficher = (id) => {
    let idLot = id.lot_id;
    let selection = [];

    this.props.requetesLots.forEach((item) => {
      if (item.requete_lot_lot.lot_id !== 0) {
        selection.push(item.requete_lot_lot);
      }
    });
    const appartement = selection.find((item) => item.lot_id === idLot);

    this.props.afficherAppartement(appartement);
  };

  onDateRangeChange = (e) => {
    this.setState({ dates: e ? [e[0], e[1]] : [null, null] });
  };

  onSelectLot = (id) => {
    const etats = this.props.parametre.parametre_blocage_option
      ? ["r", "o", "b", "n"]
      : ["r", "b", "n"];
    // Check périodes disponibles
    if (
      !this.props.locations.some(
        (item) =>
          item.location_lot.lot_id === id &&
          (dayjs(this.state.dates[0]).isBetween(
            dayjs(item.location_date_debut, "YYYY-MM-DD"),
            dayjs(item.location_date_fin, "YYYY-MM-DD"),
            undefined,
            "[)"
          ) ||
            dayjs(this.state.dates[1]).isBetween(
              dayjs(item.location_date_debut, "YYYY-MM-DD"),
              dayjs(item.location_date_fin, "YYYY-MM-DD"),
              undefined,
              "(]"
            )) &&
          etats.includes(item.location_etat)
      )
    ) {
      this.props
        .saveRequeteLot({
          requete_lot_requete_id: this.props.requete.requete_id,
          requete_lot_lot_id: id,
          requete_lot_date_debut: this.state.dates[0].format("YYYY-MM-DD"),
          requete_lot_date_fin: this.state.dates[1].format("YYYY-MM-DD"),
          requete_lot_type: 1,
        })
        .then((res) => {
          if (!this.state.requete.requete_recherche) {
            this.props.saveRecherche({
              recherche_alur: false,
              recherche_court_terme: true,
              recherche_long_terme: false,
              recherche_date_debut: this.state.dates[0].format("YYYY-MM-DD"),
              recherche_date_fin: this.state.dates[1].format("YYYY-MM-DD"),
              recherche_type_contrat: null,
              recherche_prix_min: null,
              recherche_prix_max: null,
              recherche_couchages: null,
              recherche_surfaces: null,
              recherche_type_lot: null,
              recherche_requete_id: this.props.requete.requete_id,
              recherche_equipement: [],
              recherche_quartier: [],
              recherche_metro: [],
            });
          }
          toast.success("Lot ajouté !", { containerId: "A" });
        })
        .catch((err) =>
          toast.error("Erreur lors de l'ajout du lot !", {
            containerId: "A",
          })
        );
    } else {
      toast.error("Les dates sélectionnées ne sont pas disponibles pour ce lot", {
        containerId: "A",
      });
    }
  };

  mettreOption = () => {
    if (this.state.selectedRows.length === 1) {
      const item = this.state.selectedRows[0];

      const getTypeContrat = (lot) => {
        return lot.lot_court_terme && !lot.lot_alur && !lot.lot_long_terme ? 3 : null;
      };

      if (dayjs(item.requete_lot_date_fin).diff(dayjs(item.requete_lot_date_debut)) > 0) {
        const jsonData = {
          location_date_creation: formatDateToBDD(),
          location_date_debut: item.requete_lot_date_debut,
          location_date_fin: item.requete_lot_date_fin,
          location_acompte_propose: 0.0,
          location_remarques: "/",
          location_desiderata: "/",
          location_fin_option: "/",
          location_etat: "o",
          location_nb_bebes: 0,
          location_nb_adultes: 0,
          location_nb_enfants: 0,
          location_montant_propose: 0,
          location_pourcentage_acompte: 0,
          location_heure_arrivee: "10:00",
          location_heure_depart: "11:45",
          location_entree: true,
          location_sortie: false,
          location_menage: true,
          location_type_contrat: getTypeContrat(item.requete_lot_lot),
          location_remise_fidelite: true,
          location_lot_id: item.requete_lot_lot.lot_id,
          location_requete_id: this.state.requete.requete_id,
          location_bail_signe: false,
          location_info_sejour: false,
          location_chauffeur_reserve: false,
        };

        let checkOption = true;
        if (this.props.parametre.parametre_parcours_client) {
          if (this.state.requete.requete_groupe) {
            const etape = this.props.parcoursClients.find((item) => {
              return (
                item.parcours_client_groupe.groupe_id ===
                  this.state.requete.requete_groupe.groupe_id &&
                item.parcours_client_option === true
              );
            });
            checkOption = this.props.currentOrdre + 1 >= etape?.parcours_client_ordre;
          }
        }

        // créer la location
        if (checkOption) {
          this.props.saveLocation(jsonData).then((res) => {
            this.setState({ suppressionOption: true });
            this.props.syncRequete({ ...this.props.requete, update: true });
            this.props.onChangeTab("3");
          });
        } else {
          toast.error("La mise en option n'est pas disponible pour l'étape en cours !", {
            containerId: "A",
          });
        }
      } else {
        toast.error("Mise en option impossible, la durée sélectionnée est de 0 jour !", {
          containerId: "A",
        });
      }
    } else {
      toast.error("Veuillez sélectionner un seul appartement !", {
        containerId: "A",
      });
    }
  };

  enleverOption = () => {
    if (this.state.selectedRows.length === 1) {
      const location = { ...this.props.location };

      if (location !== undefined) {
        location.location_lot_id = location.location_lot.lot_id;
        location.location_requete_id = location.location_requete.requete_id;
        location.location_etat = "a";
        this.props
          .updateLocation(location, location.location_id)
          .then(() => {
            this.props.syncRequete({ ...this.props.requete, update: true });
            this.setState({ suppressionOption: false });
            toast.success(`L'option' a été supprimée`, {
              containerId: "A",
            });
          })
          .catch((err) => console.log("err", err));
      }
    }
  };

  proposer = () => this.props.onChangeTab("2", null, "Proposition");

  setBackgroundColor = (item) => {
    if (
      this.props.location &&
      item.requete_lot_lot.lot_id === this.props.location.location_lot.lot_id
    ) {
      switch (this.props.location.location_etat) {
        case "r":
          return "row-reserved-bg";
        case "o":
          return "row-option-bg";
        case "a":
          return "row-canceled-bg";
        default:
          return "row-no-bg";
      }
    } else {
      return "row-no-bg";
    }
  };

  getDateDebut = (date, row) => {
    if (
      this.props.location &&
      row.requete_lot_lot.lot_id === this.props.location.location_lot.lot_id
    ) {
      date = this.props.location.location_date_debut;
    }
    return formatDateToAPP(date);
  };

  getDateFin = (date, row) => {
    if (
      this.props.location &&
      row.requete_lot_lot.lot_id === this.props.location.location_lot.lot_id
    ) {
      date = this.props.location.location_date_fin;
    }
    return formatDateToAPP(date);
  };

  render() {
    const { lotsAjoutables } = this.state;
    const {
      home,
      isArchive,
      // requete,
    } = this.props;

    const order = {
      option: home ? 3 : 3,
      proposer: home ? 2 : 2,
      ajoutRapide: home ? 1 : 1,
    };

    const requetesLots = this.props.requetesLots.filter((req) => req.requete_lot_type === 1);

    const content = (
      <>
        <Row gutter={12}>
          <Col span={22}>
            <RangePicker
              disabled={isArchive}
              format={dateFormat}
              getPopupContainer={(trigger) => trigger.parentElement}
              onCalendarChange={this.onDateRangeChange}
            />
          </Col>
          {this.state.dates[0]?.isBefore(dayjs(), "day") && (
            <Col span={2}>
              <Tooltip title="Dates antérieures">
                <WarningOutlined />
              </Tooltip>
            </Col>
          )}
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={22}>
            <Select
              disabled={isArchive}
              showSearch
              optionLabelProp="label"
              placeholder="Lots"
              style={{ width: "100%" }}
              onSelect={this.onSelectLot}
              dropdownRender={(menu) => <div>{menu}</div>}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <OptGroup label="Lots">
                {lotsAjoutables &&
                  lotsAjoutables.map((item, i) => {
                    return (
                      <Option key={i} value={item.lot_id} label={`${item.lot_reference}`}>
                        <Row>
                          <Col sm={8}>{`${item.lot_reference}`}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </Select>
          </Col>
        </Row>
      </>
    );

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let option = false;
        if (selectedRows.length === 1) {
          if (
            this.props.location &&
            selectedRows[0].requete_lot_lot.lot_id === this.props.location.location_lot.lot_id
          ) {
            if (this.props.location.location_etat === "o") option = true;
          }
        }
        this.setState({ selectedRows, suppressionOption: option });
      },
    };

    const footer = this.state.requete && (
      <div>
        <Row gutter="20" style={{ display: "flex", textAlign: "center" }}>
          <Col span={8} order={order.option}>
            {this.state.suppressionOption ? (
              <Button
                disabled={isArchive || this.state.selectedRows.length === 0}
                key={"1"}
                type="primary"
                size="small"
                style={{
                  background: "#ffbf00",
                  borderColor: "#ffbf00",
                }}
                onClick={this.enleverOption}
              >
                Enlever Option
              </Button>
            ) : (
              <Popconfirm
                title="Confirmez vous la mise en option pour ces dates ?"
                disabled={isArchive || this.props.location || this.state.selectedRows.length === 0}
                onConfirm={() => this.mettreOption()}
              >
                <Button
                  disabled={
                    isArchive || this.props.location || this.state.selectedRows.length === 0
                  }
                  key={"1"}
                  type="primary"
                  size="small"
                >
                  Mettre Option
                </Button>
              </Popconfirm>
            )}
          </Col>
          <Col span={8} order={order.proposer}>
            <Button
              disabled={isArchive || this.state.selectedRows.length === 0}
              key={"2"}
              type="primary"
              size="small"
              onClick={this.proposer}
            >
              Courriel
            </Button>
          </Col>
          {!this.props.location && (
            <Col span={8} order={order.ajoutRapide}>
              <Popover placement="bottom" content={content} trigger="click">
                <Button disabled={isArchive} key={"4"} type="primary" size="small">
                  Ajout Rapide
                </Button>
              </Popover>
            </Col>
          )}
        </Row>
      </div>
    );

    return (
      <>
        <Card
          className="requete-card"
          size={home ? "small" : "large"}
          bordered={false}
          disabled={isArchive}
        >
          <Table
            size={home ? "small" : "small"}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowClassName={(record) => this.setBackgroundColor(record)}
            footer={() => footer}
            style={{ minHeight: home ? 200 : 220 }}
            // title={() => tableHeader}
            dataSource={requetesLots}
            showSorterTooltip={false}
            pagination={false}
            scroll={{ x: "max-content", y: home ? 200 : 145 }}
            rowKey="key"
          >
            <Column
              width={50}
              title="Réf."
              dataIndex="requete_lot_lot"
              key="requete_lot_lot.lot_id"
              render={(lot) => <>{lot.lot_reference}</>}
            />
            <Column
              title="Date"
              colSpan={2}
              key="requete_lot_date_debut"
              dataIndex="requete_lot_date_debut"
              render={(date, row) => (date ? `Du ${this.getDateDebut(date, row)}` : "")}
            />
            <Column
              title="Date"
              colSpan={0}
              key="requete_lot_date_fin"
              dataIndex="requete_lot_date_fin"
              render={(date, row) => (date ? `au ${this.getDateFin(date, row)}` : "")}
            />
            <Column
              width={40}
              title="Apt"
              key="requete_lot_lot"
              dataIndex="requete_lot_lot"
              render={(appartement) => (
                <Button disabled={isArchive} onClick={() => this.onClickAfficher(appartement)}>
                  <EyeOutlined />
                </Button>
              )}
            />
            <Column
              width={40}
              title=""
              key="requete_lot_lot"
              dataIndex="requete_lot_lot"
              render={(appartement) => (
                <Popconfirm
                  disabled={isArchive}
                  title="Supprimer cette recherche de la liste ?"
                  onConfirm={() => this.onConfirmDelete(appartement)}
                >
                  <Button disabled={isArchive}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              )}
            />
          </Table>
        </Card>
      </>
    );
  }
}

export default FormApptSelectionnes;
