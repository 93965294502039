import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { Button, Col, Divider, Form, Modal, Progress, Row, Select, Table, Typography } from "antd";
import CustomSelect from "../CustomFormItem/Select";
import dayjs from "dayjs";
import { formatDateToAPP, round } from "../../utils/tools";
import { toast } from "react-toastify";
import { EyeOutlined } from "@ant-design/icons";
import ListeQuittancementPDF from "../Pdf/ListeQuittancementPDF";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";
import AvisEcheancePDF from "../Pdf/AvisEcheancePDF";
import FormMailMultiple2 from "../FormMailMultiple2";

const { Option, OptGroup } = Select;
const { Text } = Typography;

class FormQuittancement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showProgress: false,
      percent: 0,
      mois: dayjs().add(1, "month").format("MM"),
      annee: dayjs().format("YYYY"),
      quittances: [],
      selectedRowQuittancementKeys: [],
      selectedRowsQuittancement: [],
      modalListe: false,
      modalPdf: false,
      modalMail: false,
      idPdf: null,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.props.locations && this.init(this.props.locations, this.props.idLocation);
  }

  UNSAFE_componentWillReceiveProps(props) {
    props.locations && this.init(props.locations, null);
  }

  init = (locations, idLocation) => {
    const quittances = locations
      .filter(
        (location) =>
          (location.location_type_contrat === 1 || location.location_type_contrat === 2) &&
          dayjs(location.location_date_fin, "YYYY-MM-DD").isAfter(
            dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY")
          ) &&
          dayjs(location.location_date_debut, "YYYY-MM-DD").isSameOrBefore(
            dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY")
          )
      )
      .flatMap((location) => {
        return !this.isAlreadyCreated(location)
          ? {
              id: location.location_id,
              locataire: location.location_requete?.requete_locataire,
              lot: location.location_lot,
              requete: location.location_requete,
              date_debut: dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY").startOf(
                "month"
              ),
              date_fin: this.getDateFin(location.location_date_fin),
              location_ligne: location.location_ligne.filter(
                (ligne) =>
                  ligne.location_ligne_date_suppression === null ||
                  dayjs(ligne.location_ligne_date_suppression).isAfter(
                    dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY")
                  )
              ),
              montant: location.location_ligne
                .filter(
                  (ligne) =>
                    ligne.ligne_location_permanente === true ||
                    ligne.location_ligne_date_suppression === null ||
                    dayjs(ligne.location_ligne_date_suppression).isAfter(
                      dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY")
                    )
                )
                .reduce((a, b) => a + parseFloat(b.location_ligne_montant), 0),
              fin: dayjs(location.location_date_fin, "YYYY-MM-DD").format("DD/MM/YYYY"),
              solde: this.getSolde(location),
              quittance: this.getQuittance(location),
            }
          : [];
      });
    this.setState({ showProgress: false, quittances }, () => this.initLocation(idLocation));
  };

  getSolde = (location) => {
    let solde = 0;
    const prevQuittances = this.props.quittances?.filter(
      (quittance) =>
        quittance.quittance_location_id === location.location_id &&
        dayjs(quittance.quittance_date_debut).isBefore(
          dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY")
        )
    );
    if (prevQuittances.length > 0) {
      const prevQuittancesIds = prevQuittances.map((q) => q.quittance_id);
      const prevReglements = this.props.reglements?.filter((r) =>
        prevQuittancesIds.includes(r.reglement_quittance.quittance_id)
      );
      const totalQuittances = prevQuittances.reduce((sum, quittance) => {
        // Ajouter les montants de toutes les lignes de la quittance actuelle
        const totalLignes = quittance.quittance_ligne.reduce(
          (ligneSum, ligne) => ligneSum + parseFloat(ligne.quittance_ligne_montant),
          0
        );
        return sum + totalLignes;
      }, 0);
      const totalReglements = prevReglements
        ? prevReglements.reduce((a, b) => a + parseFloat(b.reglement_montant), 0)
        : 0;
      solde = parseFloat(totalQuittances).toFixed(2) - parseFloat(totalReglements).toFixed(2);
    }
    return solde;
  };

  getQuittance = (location) => {
    const prevQuittances = this.props.quittances?.filter(
      (quittance) =>
        quittance.quittance_location_id === location.location_id &&
        dayjs(quittance.quittance_date_debut).isBefore(
          dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY")
        )
    );
    const quittance =
      prevQuittances.length > 0
        ? prevQuittances.sort((a, b) => b.quittance_id - a.quittance_id)[0]
        : null;
    if (quittance) {
      const totalQuittance = quittance.quittance_ligne.reduce(
        (a, b) => a + parseFloat(b.quittance_ligne_montant),
        0
      );
      const reglements = this.props.reglements?.filter(
        (r) => r.reglement_quittance.quittance_id === quittance.quittance_id
      );
      const totalReglements = reglements
        ? reglements.reduce((a, b) => a + parseFloat(b.reglement_montant), 0)
        : 0;
      quittance.solde =
        parseFloat(totalQuittance).toFixed(2) - parseFloat(totalReglements).toFixed(2);
    }
    return quittance;
  };

  initLocation = (id) => {
    if (id !== null && id !== undefined) {
      const row = this.state.quittances.find((qui) => qui.id === id);
      this.setState({
        selectedRowQuittancementKeys: row ? [row.id] : [],
        selectedRowsQuittancement: row ? [row] : [],
      });
    }
  };

  isAlreadyCreated = (location) => {
    return this.props.quittances.some(
      (quittance) =>
        quittance.quittance_location_id === location.location_id &&
        dayjs(formatDateToAPP(quittance.quittance_date_debut), "DD/MM/YYYY").format("MM") ===
          this.state.mois &&
        dayjs(formatDateToAPP(quittance.quittance_date_debut), "DD/MM/YYYY").format("YYYY") ===
          this.state.annee
    );
  };

  getDateFin = (date) => {
    const endDate = dayjs(date, "YYYY-MM-DD");
    const lastDayOfMonth = dayjs(`${this.state.mois}/${this.state.annee}`, "MM/YYYY").endOf(
      "month"
    );
    return endDate.isBefore(lastDayOfMonth) ? endDate : lastDayOfMonth;
  };

  onChangeMois = (value) => {
    this.setState({ mois: value }, () => this.init(this.props.locations));
  };

  onChangeAnnee = (value) => {
    this.setState({ annee: value }, () => this.init(this.props.locations));
  };

  onFinishQuittancement = () => {
    this.setState({ showProgress: true, loading: true }, () => {
      this.prepareQuittance(0, this.state.selectedRowsQuittancement.length);
    });
  };

  prepareQuittance = (i, len) => {
    if (i < len) {
      const ligne = this.state.selectedRowsQuittancement[i];
      let montant = 0;
      const quittance = {
        quittance_libelle: "Solde de location",
        quittance_date_debut: dayjs(ligne.date_debut).format("DD/MM/YYYY"),
        quittance_date_fin: dayjs(ligne.date_fin).format("DD/MM/YYYY"),
        quittance_location_id: ligne.id,
        quittance_ligne: [],
      };
      const nbJours =
        dayjs(ligne.date_fin, "DD/MM/YYYY").diff(dayjs(ligne.date_debut, "DD/MM/YYYY"), "days") + 1;
      const nbJoursInMonth = dayjs(ligne.date_debut, "DD/MM/YYYY").daysInMonth();
      ligne.location_ligne.forEach((item) => {
        const montantLigne = !item.location_ligne_rubrique.rubrique_montant_fixe
          ? nbJours !== nbJoursInMonth
            ? round((item.location_ligne_montant / nbJoursInMonth) * nbJours, 2)
            : round(item.location_ligne_montant, 2)
          : round(item.location_ligne_montant, 2);
        const quittanceLigne = {
          quittance_ligne_quantite: 1,
          quittance_ligne_libelle: item.location_ligne_libelle
            ? item.location_ligne_libelle
            : item.location_ligne_rubrique.rubrique_libelle,
          quittance_ligne_montant: montantLigne,
          quittance_ligne_rubrique_id: item.location_ligne_rubrique.rubrique_id,
        };
        montant += montantLigne;
        quittance.quittance_ligne.push(quittanceLigne);
      });
      // SAVE Quittance + QuittanceLignes
      this.props.saveQuittance(quittance).then((res) => {
        // SAVE Ecriture + EcritureLignes
        this.setState({ percent: parseInt((i / len) * 100) }, () => {
          this.createEcriture(
            res,
            ligne,
            i,
            ligne.locataire.locataire_affectations[0].locataire_affectation_compte_id,
            round(montant, 2)
          );
        });
      });
    } else {
      toast.success("Création des quittances effectuée !", { containerId: "A" });
      this.setState(
        {
          loading: false,
          percent: 100,
          quittances: [],
          selectedRowQuittancementKeys: [],
          selectedRowsQuittancement: [],
        },
        () => this.init(this.props.locations)
      );
    }
  };

  createEcriture = (quittance, ligne, i, idCompte, montant) => {
    const ecritureLignes = [];
    const ligneDebit = {
      ecriture_ligne_libelle: `Contrat n°${ligne.id} du ${dayjs(ligne.date_debut).format(
        "DD/MM/YYYY"
      )} au ${dayjs(ligne.date_fin).format("DD/MM/YYYY")} (${
        ligne.locataire.locataire_personne.personne_nom
      })`,
      ecriture_ligne_montant_debit: montant,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: idCompte,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    const ligneCredit = {
      ecriture_ligne_libelle: `Contrat n°${ligne.id} du ${dayjs(ligne.date_debut).format(
        "DD/MM/YYYY"
      )} au ${dayjs(ligne.date_fin).format("DD/MM/YYYY")} (${
        ligne.locataire.locataire_personne.personne_nom
      })`,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: montant,
      ecriture_ligne_compte_id: this.props.comptes.find(
        (compte) => compte.compte_compte_general === "480000"
      ).compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: this.props.journals.find((journal) => journal.journal_code === "48")
        .journal_id,
      ecriture_date_ecriture: quittance.quittance_date_debut,
      ecriture_libelle: `Contrat n°${ligne.id} du ${dayjs(ligne.date_debut).format(
        "DD/MM/YYYY"
      )} au ${dayjs(ligne.date_fin).format("DD/MM/YYYY")} (${
        ligne.locataire.locataire_personne.personne_nom
      })`,
      ecriture_action: `QUI${quittance.quittance_id}`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecriture)
      .then((res) => this.prepareQuittance(i + 1, this.state.selectedRowsQuittancement.length))
      .catch((err) => toast.error("Erreur création écriture !", { containerId: "A" }));
  };

  onClickAfficher = (requete) => {
    const pane = { key: "26", title: "Recherche de disponibilités", content: "" };
    this.props.addPanesRequete(pane, "26", requete);
  };

  createPdfListe = (lignesQuittances) => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <ListeQuittancementPDF utilisateur={this.props.utilisateur} quittances={lignesQuittances} />
    );
    const pdf = {
      titre: "Liste récapitulative",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalListe: true });
        }.bind(this)
      );
  };

  createPdf = (lignesQuittances) => {
    const htmls = [];
    lignesQuittances.forEach((ligne) => {
      const html = ReactDOMServer.renderToStaticMarkup(
        <AvisEcheancePDF avis={ligne} utilisateur={this.props.utilisateur} />
      );
      htmls.push(html);
    });
    const societe = this.props.utilisateur.utilisateur_etablissement.etablissement_societe;
    const pdf = {
      titre: "Avis Échéance",
      destinataire: `${societe.societe_zone_libre_1 ? societe.societe_zone_libre_1 + "<br/>" : ""}${
        societe.societe_zone_libre_2 ? societe.societe_zone_libre_2 + "<br/>" : ""
      }${societe.societe_zone_libre_3 ? societe.societe_zone_libre_3 + "<br/>" : ""}${
        societe.societe_zone_libre_4 ? societe.societe_zone_libre_4 + "<br/>" : ""
      }${societe.societe_zone_libre_5 ? societe.societe_zone_libre_5 + "<br/>" : ""}`,
      pdf_page: [],
    };
    htmls.forEach((html) => {
      const pdfPage = {
        html: html,
      };
      pdf.pdf_page.push(pdfPage);
    });
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalPdf: true });
        }.bind(this)
      );
  };

  // getSolde = (quittance, ecritures) => {
  //   const idCompte = quittance.locataire.locataire_affectations[0].locataire_affectation_compte_id;
  //   return ecritures
  //     .flatMap((ecriture) => ecriture.ecriture_ligne)
  //     .filter((ligne) => ligne.ecriture_ligne_compte_id === idCompte)
  //     .reduce(
  //       (total, ligne) =>
  //         total + (ligne.ecriture_ligne_montant_debit - ligne.ecriture_ligne_montant_credit),
  //       0
  //     );
  // };

  prepareMailing = () => {
    if (this.props.tags.length > 0) this.setState({ modalMail: true });
    else toast.error("Aucun dossier trouvé pour stocker les documents !", { containerId: "A" });
  };

  closeModalListe = () => {
    this.setState({ modalListe: false });
  };

  closeModalPdf = () => {
    this.setState({ modalPdf: false });
  };

  closeModalMail = () => {
    this.setState({ modalMail: false });
  };

  render() {
    const {
      loading,
      showProgress,
      percent,
      mois,
      annee,
      quittances,
      selectedRowQuittancementKeys,
      selectedRowsQuittancement,
      modalListe,
      modalPdf,
      modalMail,
      idPdf,
    } = this.state;

    const quittancesColumns = [
      {
        title: "#",
        dataIndex: "id",
        render: () => <></>,
        width: "0%",
      },
      {
        title: "Locataire",
        dataIndex: "locataire",
        render: (locataire) => (
          <>
            {locataire.locataire_code} {locataire.locataire_personne.personne_nom}{" "}
            {locataire.locataire_personne.personne_prenom}
          </>
        ),
        width: "40%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.locataire.locataire_personne.personne_nom.localeCompare(
            b.locataire.locataire_personne.personne_nom
          ),
      },
      {
        title: "Date début",
        dataIndex: "date_debut",
        key: "date_debut",
        render: (date_debut) => (date_debut ? dayjs(date_debut).format("DD/MM/YYYY") : ""),
        width: "20%",
      },
      {
        title: "Date fin",
        dataIndex: "date_fin",
        key: "date_fin",
        render: (date_fin) => (date_fin ? dayjs(date_fin).format("DD/MM/YYYY") : ""),
        width: "20%",
      },
      {
        title: "Montant",
        align: "right",
        dataIndex: "montant",
        render: (montant) => (
          <Text style={{ float: "right" }}>
            {parseFloat(montant).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })}
          </Text>
        ),
        width: "20%",
      },
      {
        title: "Requete",
        align: "right",
        dataIndex: "requete",
        render: (requete) => (
          <Button onClick={() => this.onClickAfficher(requete)} style={{ float: "right" }}>
            <EyeOutlined />
          </Button>
        ),
        width: "10%",
      },
    ];

    const rowQuittancementSelection = {
      onChange: (selectedRowQuittancementKeys, selectedRowsQuittancement) => {
        this.setState({ selectedRowQuittancementKeys, selectedRowsQuittancement });
      },
      selectedRowKeys: selectedRowQuittancementKeys,
    };

    const years = [];
    for (let i = -1; i <= 10; i++) {
      years.push(
        <Option
          label={dayjs().add(i, "year").format("YYYY")}
          value={dayjs().add(i, "year").format("YYYY")}
        >
          {dayjs().add(i, "year").format("YYYY")}
        </Option>
      );
    }

    return (
      <Form
        ref={this.formRef}
        onFinish={this.onFinishQuittancement}
        initialValues={{ quittancement_mois: mois, quittancement_annee: annee }}
        style={{ padding: "20px" }}
      >
        <Modal
          footer={null}
          title="Liste récapitulative"
          open={modalListe}
          onCancel={this.closeModalListe}
        >
          {idPdf && (
            <Button type="text" onClick={() => openPdf(idPdf)}>
              Liste récapitulative
            </Button>
          )}
        </Modal>

        <Modal footer={null} title="Avis d'échéances" open={modalPdf} onCancel={this.closeModalPdf}>
          {idPdf && (
            <Button type="text" onClick={() => openPdf(idPdf, "b")}>
              Avis d'échéances
            </Button>
          )}
        </Modal>

        <Modal
          width="800px"
          footer={null}
          title="Mailing"
          open={modalMail}
          onCancel={this.closeModalMail}
        >
          <FormMailMultiple2
            utilisateur={this.props.utilisateur}
            parametre={this.props.parametre}
            tags={this.props.tags}
            lignesQuittances={selectedRowsQuittancement}
            saveEmail={this.props.saveEmail}
            saveDocument={this.props.saveDocument}
          />
        </Modal>

        <Divider orientation="left">Générer les appels de :</Divider>
        <Row gutter={12}>
          <Col span={12}>
            <CustomSelect
              label="Mois"
              inputName="quittancement_mois"
              formItemName="quittancement_mois"
              objectValue={mois}
              onChange={(value) => this.onChangeMois(value)}
              rules={{ required: true, message: "Mois obligatoire" }}
            >
              <OptGroup label="Mois">
                <Option label="Janvier" value="01">
                  Janvier
                </Option>
                <Option label="Février" value="02">
                  Février
                </Option>
                <Option label="Mars" value="03">
                  Mars
                </Option>
                <Option label="Avril" value="04">
                  Avril
                </Option>
                <Option label="Mai" value="05">
                  Mai
                </Option>
                <Option label="Juin" value="06">
                  Juin
                </Option>
                <Option label="Juillet" value="07">
                  Juillet
                </Option>
                <Option label="Aout" value="08">
                  Aout
                </Option>
                <Option label="Septembre" value="09">
                  Septembre
                </Option>
                <Option label="Octobre" value="10">
                  Octobre
                </Option>
                <Option label="Novembre" value="11">
                  Novembre
                </Option>
                <Option label="Décembre" value="12">
                  Décembre
                </Option>
              </OptGroup>
            </CustomSelect>
          </Col>
          <Col span={12}>
            <CustomSelect
              label="Mois"
              inputName="quittancement_annee"
              formItemName="quittancement_annee"
              objectValue={annee}
              onChange={(value) => this.onChangeAnnee(value)}
              rules={{ required: true, message: "Année obligatoire" }}
            >
              <OptGroup label="Année">{years}</OptGroup>
            </CustomSelect>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={22}>
            <Table
              columns={quittancesColumns}
              dataSource={quittances}
              pagination={false}
              rowKey="id"
              rowSelection={{
                type: "checkbox",
                ...rowQuittancementSelection,
              }}
              scroll={{ y: 600 }}
              showSorterTooltip={false}
              size="small"
            />
          </Col>
          <Col span={2}>
            <Row style={{ marginBottom: 10 }}>
              <Button type="primary" onClick={() => this.createPdfListe(selectedRowsQuittancement)}>
                Liste récap.
              </Button>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Button type="primary" onClick={() => this.createPdf(selectedRowsQuittancement)}>
                Générer PDF
              </Button>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Button type="primary" onClick={() => this.prepareMailing()}>
                Envoyer par mail
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>{showProgress && <Progress percent={percent} />}</Row>
        <Row gutter={12} style={{ marginTop: 20 }}>
          <Col offset={22} span={2}>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              disabled={selectedRowsQuittancement.length === 0}
              loading={loading}
              style={{ float: "right" }}
            >
              Validation
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default FormQuittancement;
